<template>
  <div class="addAccount">
    <!-- 添加银行卡 -->
    <div class="topBox box">
      <van-field
        v-model="selfName"
        input-align="left"
        label="持卡人"
        readonly
        placeholder="请输入姓名"
      />
      <van-field
        v-model="accountVal"
        is-link
        readonly
        name="picker"
        label="开户银行"
        placeholder="请选择开户银行"
        @click="showAccountlist = true"
      />
      <van-popup v-model:show="showAccountlist" position="bottom">
        <van-picker
          :columns="accountList"
          @confirm="onSelect"
          @cancel="showAccountlist = false"
          :columns-field-names="customFieldName"
        />
      </van-popup>

      <van-field
        v-model="selfNum"
        input-align="left"
        label="银行卡号"
        placeholder="请输入银行卡号"
        type="number"
      />
    </div>
    <div class="middleBox box">
      <van-field
        v-model="selfPhone"
        input-align="left"
        type="number"
        label="预留手机号"
        placeholder="请输入预留手机号"
      />
    </div>
    <div class="bottomBox box">
      <van-field
        v-model="sms"
        center
        clearable
        type="digit"
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            v-show="canSendCode"
            @click="sendSms"
          >发送验证码
          </van-button
          >
          <van-button size="small" type="primary" v-show="!canSendCode">
            <span>{{ count }}秒</span>
          </van-button
          >
        </template>
      </van-field>
    </div>
    <div class="submitBtn" @click="submit">提交</div>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import API from '@/server/api'
import { Toast } from 'vant'

export default {
  setup () {
    const Routes = useRoute()
    const Router = useRouter()

    const state = reactive({
      type: '',

      selfName: '',
      showAccountlist: false, // 开户银行列表
      accountVal: '',
      selfNum: '',
      selfPhone: '',
      sms: '',
      accountid: '',

      userId: '',
      loanNo: '',
      creditNo: '',

      accountList: [],
      count: 60,
      needVerify: '',
      canSendCode: true,
      timer: null,

      form: {
        userId: '',
        bankAccount: '',
        creditNo: '',
        mobile: '',
        verifyCode: ''
      }
    })

    const onSelect = value => {
      state.accountVal = value.openBankName
      state.accountid = value.id
      state.showAccountlist = false
    }

    // 路由参数
    if (Routes.query && Routes.query.type) {
      state.type = Routes.query.type
    }

    // 显示用户默认信息(姓名、手机号)
    const initUserInfo = async () => {
      try {
        const res = await API.getuserbindInfo()
        if (res.success) {
          const { mobile, ownerName } = res.data
          state.selfName = ownerName
          state.selfPhone = mobile
        }
      } catch (err) {
        Toast(err.message)
      }
    }

    const customFieldName = {
      text: 'openBankName',
      id: 'id'
    }

    // 点击提交
    const submit = () => {
      if (!state.selfName) {
        Toast('请输入持卡人姓名')
        return
      }
      if (!state.accountVal) {
        Toast('请选择开户银行')
        return
      }
      if (!state.selfNum) {
        Toast('请输入银行卡号')
        return
      }
      if (!state.selfPhone) {
        Toast('请输入预留手机号')
        return
      }
      if (!(/^1[3456789]\d{9}$/.test(state.selfPhone))) {
        Toast('手机号格式有误')
        return
      }
      if (!state.sms) {
        Toast('请输入短信验证码')
        return
      }
      backResult()
    }

    // 点击提交判断是否二次绑卡
    const backResult = async () => {
      const params = {
        userId: state.userId,
        bankAccount: state.selfNum,
        creditNo: state.creditNo,
        mobile: state.selfPhone,
        verifyCode: state.sms
      }

      if (state.type !== 'loan') {
        params.loanNo = state.loanNo
        params.creditNo = ''
      }

      const res = await API.ensureBind({ ...params })
      if (res.success) {
        Toast('提交成功')
        setTimeout(() => {
          Router.back()
        }, 1000)
      }
    }

    // 发送验证码
    const sendSms = async () => {
      if (!state.selfName) {
        Toast('请输入持卡人姓名')
        return
      }
      if (!state.accountVal) {
        Toast('请选择开户银行')
        return
      }
      if (!state.selfNum) {
        Toast('请输入银行卡号')
        return
      }
      if (!state.selfPhone) {
        Toast('请输入预留手机号')
        return
      }
      if (!(/^1[3456789]\d{9}$/.test(state.selfPhone))) {
        Toast('手机号格式有误')
        return
      }
      const params = {
        userId: state.userId,
        bankAccount: state.selfNum,
        creditNo: state.creditNo,
        mobile: state.selfPhone
      }

      if (state.type !== 'loan') {
        params.loanNo = state.loanNo
        params.creditNo = ''
      }

      try {
        const res = await API.sendSms({ ...params })
        if (res.success) {
          Toast('发送成功！')
          countdowns()
        }
      } catch (err) {
        Toast(err.message)
      }
    }

    // 查看绑卡列表
    const init = async () => {
      try {
        const res = await API.bankCard()
        if (res.success) state.accountList = res.data
      } catch (err) {
        Toast(err.message)
      }
    }

    // 初始化
    onMounted(() => {
      state.creditNo = localStorage.getItem('creditNo')
      state.userId = localStorage.getItem('userId')
      state.loanNo = localStorage.getItem('loanNos')

      console.log(state.creditNo, '授信单号')

      init()
      initUserInfo()
    })

    // 倒计时
    const countdowns = () => {
      const TIME_COUNT = 60
      if (!state.timer) {
        state.count = TIME_COUNT
        state.canSendCode = false
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--
          } else {
            state.canSendCode = true
            clearInterval(state.timer)
            state.timer = null
          }
        }, 1000)
      }
    }

    return {
      ...toRefs(state),
      onSelect,
      submit,

      sendSms,
      countdowns,
      customFieldName
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
<style>
.box img {
  width: 48px;
  height: 48px;
}
</style>
